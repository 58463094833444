import React from 'react';
import Marquee from 'react-fast-marquee';

const Content5 = () => {
    return (
    
    <div>
        <h2 class="text-center text-yellow-900 text-3xl "><b>Certifications</b></h2>
        
<div className='container h-auto w-full'></div>
<div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-1">
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">

      <div class="lg:w-1/3 md:w-1/2 p-4 w-full">
      <a class="block relative h-48 rounded overflow-hidden">
  <img alt="ecommerce" class="w-full h-full object-contain mx-auto" src="https://i.pinimg.com/564x/d8/ff/16/d8ff16c9b51c5f81917f471798803935.jpg"/>
</a>


        <div class="mt-4">
         </div>
      </div>
      <div class="lg:w-1/3 md:w-1/2 p-4 w-full">
      <a class="block relative h-48 rounded overflow-hidden">
  <img alt="ecommerce" class="w-full h-full object-contain mx-auto" src="https://i.pinimg.com/564x/86/61/8c/86618c19aa88c1d05dc4f13d8987f8b6.jpg"/>
</a>
        <div class="mt-4">
        </div>
      </div>
      <div class="lg:w-1/3 md:w-1/2 p-4 w-full">
      <a class="block relative h-48 rounded overflow-hidden">
  <img alt="ecommerce" class="w-full h-full object-contain mx-auto" src="https://i.pinimg.com/564x/fd/ad/7b/fdad7b7eae4021e9929ec5256f594c5e.jpg"/>
</a>
        <div class="mt-4">
               
         </div>
      </div>
      
    

</div>
</div>

</div>
    
  )
};

export default Content5