import { Card } from '@mui/material';
import React from 'react';
import { ExternalLink } from 'react-external-link';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);
const Aboutmillets = () => {
    return (
<div>
<div>
          <img src='https://apeda.gov.in/milletportal/assets/img/Millet_banner.jpg'></img>
        </div>
<div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-26">
      <div className="max-w-full mb-4 md:mx-auto sm:text-justify lg:max-w-2xl md:mb-2">
        
       <h2 class="text-center text-yellow-900 text-4xl ">
<b>About Millets</b>
       </h2>
     </div>
    </div>
{/* second set */}
<span className='inline-block mx-16 mb-6 md: text-lg'> 
        <p class="text-justify text-md ">
        Indian millets are a group of nutritiously rich, drought tolerant and mostly grown in the arid and semi-arid regions of India. They are small-seeded grasses belonging to the botanical family Poaceae. They constitute an important source of food and fodder for millions of resource-poor farmers and play a vital role in ecological and economic security of India. These millets are also known as "coarse cereals" or "cereals of the poor". Indian Millets are nutritionally superior to wheat and rice as they are rich in protein, vitamins and minerals. They are also gluten-free and have a low glycemic index, making them ideal for people with celiac disease or diabetes. India is among the top 5 exporters of millets in world. World export of millet has increased from $400 million in 2020 to $470 million in 2021 (ITC trade map) India exported millets worth $64.28 million in the year 2021-22, against $59.75 million in 2020-21. Share of Millet based value added products is negligible.<br/>
<br className='inline-hiddenblock'></br>
India is the largest producer as well as the largest exporter of cereal products in the world. India's export of cereals stood at Rs. 96,011.42 Crore / 12,872.64 USD Millions during the year 2021-22. Rice (including Basmati and Non-Basmati) occupy the major share in India's total cereals export with 75% (in value terms) during the same period. Whereas, other cereals including wheat represent only a 25 % share of total cereals exported from India during this period.<br/>
<Card className='mt-4'>
  <div className='bg-yellow-900 text-white rounded-md border-b-2px shadow-2px'>
    <div className='inline-block mx-24 mb-4 mt-4 '>
<b>Benefits of Millets:</b><br/>
- Millets are highly adaptive to a wide range of ecological conditions and thrive well in rain-fed; arid climate and they have minimal requirement of water, fertilizers, and pesticides.<br/>
- Health-promoting nutritious crop: Compared to other cereals they have superior micronutrient profile and bioactive flavonoids.<br/>
- Millets have a low Glycaemic Index (GI) and also associated with the prevention of diabetes.<br/>
- They are good source of minerals like iron, zinc, and calcium.<br/>
- Millets are gluten-free and can be consumed by celiac disease patients.<br/>
- Millet has a beneficial effect on the management and prevention of hyperlipidemia and risk of CVD.<br/>
- Millets are found to be helpful with the reduction of weight, BMI, and high blood pressure.<br/>
- In India, Millet is generally consumed with legumes, which creates mutual supplementation of protein, increases the amino acid content, and enhances the overall digestibility of protein.<br/>
- Millet based value-added products in ready to cook, ready to eat category are easily accessible and convenient to the urban population.<br/>
- Millets are used for dual purposes as food as well as fodder, which make it more farming efficient.<br/>
- Millet cultivation helps to reduce the carbon footprint.<br/>
</div>
</div>
</Card>
<span className='inline-block mb-4 mt-4 md: text-lg '>
<b>Indian Millet Production Scenario:</b><br/>
Millet is a type of grain that is popular in many parts of the world, especially in Africa and Asia. It is a staple food in many parts of the world, particularly in Africa and Asia. According to the World Food Programme, there are an estimated 1.2 billion people who consume millet as a part of their diet.<br/>

Millet production has remained relatively stable over the past few years, with an estimated production of 28 million metric tons in 2020. The majority of millet is produced in Africa, followed by Asia. India is the largest producer of millet, followed by Niger and China. Other major millet-producing countries include Burkina Faso, Mali, and Senegal.While millet is not a major food crop in the developed world, it plays a vital role in the diets of many people in developing countries. Millet is a drought-tolerant crop that can be grown in dry, arid climates where other crops would fail. It is also a nutritious grain that is high in fiber and essential minerals. For these reasons, millet will continue to be an important food crop in the years to come.<br/>

In India, millet production has been on the rise in recent years. India is one of the largest producers of millets &Indian farmers have been increasingly planting millet as a drought-resistant crop. The Indian government has also been promoting millet production as part of its National Food Security Mission. As a result of these factors, millet production in India is expected to continue to grow in the coming years. The graph below depicts the production trends of millets in India.<br/>
</span>
</p>
        
       </span>
       
  
    <div className='bg-yellow-500 text-white rounded-md border-b-2px shadow-2px mx-2 sm:mx-16 justifycontent-center'>
      <div className='inline-block mx-2 sm:mx-96 mb-4 mt-4 alignitems-center '>
        <img src='https://apeda.gov.in/milletportal/assets/img/Production_Scenario.jpg' alt='Image' className='w-full h-auto' />
      </div>
    



    {/* third set */}
    <span className='inline-block mx-16 mb-6 md: text-lg'> 
       <h2><b>Indian Millet Sourcing Point:</b></h2>
       <p>India is one of the leading producers and suppliers of millet, and there are a number of millet sourcing points located throughout the country. The main millet-growing states in India are Rajasthan, Maharashtra, Karnataka, Andhra Pradesh, and Madhya Pradesh These states have a large number of millet farmers who grow the grain for both domestic and international markets. In addition to the major millet producing states, there is also a number of smaller millet producing regions located throughout India. These regions include the states of Uttar Pradesh, Bihar, and Madhya Pradesh.</p>
       </span>
       </div> 
       <span className='inline-block mx-16 mb-6 md: text-sm '> 
       <h2>Note: Content reference from <ExternalLink className='text-blue-500'>https://apeda.gov.in/milletportal/about_us.html</ExternalLink></h2>
       
       <p></p>
       </span>
       
    </div>
  



        )
    }
    
    export default Aboutmillets