import React from 'react';
import { Card } from '@mui/material';
import { ExternalLink } from 'react-external-link';
const Blog = () => {
    return (

<div>
      <div className="container mx-auto">
        <div className="flex flex-row justify-center items-center mb-4 mt-4">
          
            <Card>
              <div>
                <div class="flex flex-wrap">
                    
                  <a
                    href="#"
                    class="flex flex-center block max-w-full  md:w-1/2 lg:w-1/2 p-6 bg-white border bg-gradient-to-r from-yellow-900 to-yellow-500 rounded-lg mx-auto"
                  >
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"> </h5>
      <p class=" text-white text-md text-justify"><h2><b>Millets are the new Wine</b></h2><br/>
      Millets, classified as a diverse group of grains, hold a special place in the culinary world. With over 10 types, these rain-fed crops have been cultivated for centuries across the globe. To delve deeper into the intriguing history of ancient grains and their migration patterns, Cambridge University offers a remarkable resource.

The incredible abundance of millet varieties emerges from their resilience, having withstood floods, droughts, and other environmental challenges over generations. Their adaptability is further highlighted by their popularity among birds, grazing animals, and other ecosystem participants. Each local variety, honed by specific climates, soils, and cultural practices, boasts its own distinct flavors, sizes, and cooking characteristics.

Notably, the unique attributes of millets are influenced by factors such as rainfall patterns. As millets are short-duration crops, the quality of their grains is significantly affected by the duration of daylight during the reproductive stage. Consequently, even grains sourced from the same farm and cultivated by the same farmer may exhibit slight variations depending on whether the rainfall was normal, deficient, or excessive during the growth period.

Furthermore, the location of cultivation adds another layer of diversity. For instance, foxtail millet grown on an east-facing hill slope will possess different cooking qualities and flavors compared to its counterpart grown on the nearby plains. This highlights the intricate relationship between millet cultivation, geography, and taste.

Therefore, it comes as no surprise that millets are often compared to fine wines, given their nuanced characteristics and regional variations.
</p>
    </a>
  
  
    <a
  href="#"
  className="flex flex-center block max-w-full md:w-1/2 lg:w-1/2 p-6  mx-auto"
>
  <img src="https://i.pinimg.com/564x/b4/f6/d0/b4f6d02f49042135c7c9c057b784565d.jpg" alt="Health Benefits Millets" />
</a>

</div>
    </div>
</Card>   
</div>


<div>
      <div className="container mx-auto">
        <div className="flex flex-row justify-center items-center mb-4 mt-4">
          
            <Card>
              <div>
                <div class="flex flex-wrap">
                <a
  href="#"
  className="flex flex-center block max-w-full md:w-1/2 lg:w-1/2 p-6  mx-auto"
>
  <img src="https://i.pinimg.com/564x/d4/d1/67/d4d16769aa3d390d0b86a3ecbc610ddc.jpg" alt="Health Benefits Millets" />
</a>
                  <a
                    href="#"
                    class="flex flex-center block max-w-full  md:w-1/2 lg:w-1/2 p-6 bg-white border bg-gradient-to-r from-yellow-900 to-yellow-500 rounded-lg mx-auto"
                  >
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"> </h5>
      <p class=" text-white text-md text-justify"><h2><b>Millets are the new Wine</b></h2><br/>
      Millets are a powerhouse of nutrients. They have reclaimed their space in the kitchen of those more health-conscious. Millets boost your health and improve weight loss, besides being gluten-free. Speak to any fitness enthusiast, and they will vouch for the miraculous benefits of eating millets.
      Millets are available in a variety of types, and each has its health benefits. Walk into a supermarket at any time of the year, and you will find millets in stock. That is because of their cultivation across seasons.

Consuming millets as part of your daily diet is an age-old concept. The population of central and southern India consumed millets daily until the Green Revolution made rice and wheat more accessible. However, government policies did not provide incentives for growing millets.

On the contrary, it discouraged farmers from cultivating millets. Therefore, consumption of millets reduced as the produce quantum dipped.

However, ditching rice or wheat bread entirely for millets is not healthy for your body—practice grain diversity in your diet for wholesome nutrition.

<b>What is Millets?</b><br/>
Millets are coarse grains that are traditionally grown and consumed in the Indian subcontinent for over 5000 years.

They have high nutritional value and are rich in proteins, vitamins, minerals, and fibres. Unlike other cereals, millets require little water and ground fertility. The sheer affordability of millets also tags them as “poor man’s food grain”. The world is now noticing millets for their enormous potential.

You can divide Millets into two broad categories:

<b>1. Naked grains</b><br/>

Naked grains refer to the millets devoid of the tough, indigestible husk, namely, Ragi, Jowar, and Bajra. These millets don’t require processing after their harvest. They can be consumed right after cleaning. These millets are therefore significantly cultivated today.

<b>2. Husked grains</b><br/>
Foxtail millets, Little millets, and Kodo millets belong to this second type. These types consist of an indigestible seed coat that has to be removed before consumption. The processing, once done by hand, is now mechanical and mainly used for rice than for millets, making them less popular.

Millets contain a host of micronutrients such as iron, calcium, and phosphorus. Also, they take time to digest, which doesn’t cause the blood sugar spike associated with easily digestible food. So introducing millets into your diet can help control diabetes for the same reason.

Therefore, millets are good for health.  Millet production is good for the environment. It is because they are primarily rain-fed crops. Consequently, they do not put pressure on our already diminishing water resources. Additionally, these grain crops do not attract pests and can grow perfectly well without pesticides.

<b>Types of Millets</b><br/>
Millets come in different shapes and sizes. The two broad categories discussed above contain numerous kinds of millets. We will take a look at some of these different types below:

<b>1. Foxtail Millet</b><br/>
Foxtail millet, or indigenously called Kakum/Kangni. It contains blood sugar balancing healthy carbohydrates. The iron and calcium content present in it also helps strengthen immunity. In addition, foxtail millets help regulate your blood cholesterol and increase HDL cholesterol levels in your body.

<b>2. Finger Millet/Ragi</b><br/>
Ragi is a more common name for finger millet. It is used as a healthier cereal substitute for rice and wheat. Ragi is gluten-free and rich in protein. Ragi is supposed to aid brain development in growing children.

<b>3. Pearl Millet/Bajra</b><br/>
Bajra is incredibly nutrient-dense. It contains minerals such as calcium and magnesium, protein, fibre, and iron. Practice regular consumption of pearl millet to fight against type II diabetes.

<b>4. Buckwheat</b><br/>
Go for buckwheat if your primary concern is to lose weight. It makes for a healthy food option for diabetes, helps lower blood pressure, and improves cardiovascular health. Buckwheat also fights against diseases such as gallstones, childhood asthma, and breast cancer.

<b>5. Little Millet</b><br/>
Little millet is also an excellent option for those looking to lose weight. You can eat it as a rice replacement. It is high in fibre and filled with numerous minerals such as potassium, zinc, iron, and calcium. It is also packed with the health benefits of vitamin B and works as an antioxidant for your body.

<b>7 Proven Health Benefits of Millets</b><br/>
Millets are rich in several beneficial nutrients, such as phosphorus, magnesium, copper, and manganese. Incorporate them into your diet to gain the following benefits.

<b>1. Millets Aids Weight Loss</b><br/>
The calorie content of millets is low, and they are an excellent food product for weight loss. Not just those looking to lose weight, it benefits people who are conscious of their fitness too. It helps them maintain their energy level throughout the day without having to eat to refuel themselves constantly.

Millets also keep you satiated for longer than other carbohydrates. When you consume them, you feel fuller for longer as they take time to get digested and absorbed into your body. That prevents snacking and overeating.

<b>2. Millets Keeps Your Blood Sugar Levels Low</b><br/>
Millets have a low glycaemic index. Therefore, consume millets regularly to lower your risk of developing diabetes.

<b>3. Millets Boost Your Immunity</b><br/>
Protein intake is responsible for building the body’s immunity.

Millets provide a great source of protein and can help develop and strengthen our immunity. Stronger immunity means fewer chances of you catching diseases.

<b>4. Millets Reduces Cardiovascular Risks</b><br/>
Millets contain essential fats, which provide our bodies with good fats which prevent excess fat storage as well as effectively lowers the risk of high cholesterol, strokes, and other heart complaints.

The potassium content in millets regulates your blood pressure and optimises your circulatory system.

<b>5. Millets Prevents Asthma</b><br/>
The magnesium content in millets can reduce how frequently you experience migraines. It can also bring down the severity of your asthma complaints.

The reason is, unlike wheat, they do not contain the allergens that lead to asthma and wheezing.

<b>6. Millets Helps Your Digestion</b><br/>
Millets are a rich fibre source that benefits digestion by alleviating bloating, gas, cramping, and constipation. In addition, good digestion keeps issues like gastric/colon cancer and kidney/liver complaints away.

<b>7. Millets Acts as an Antioxidant</b><br/>
Millets help your body detox because of their antioxidant properties; Quercetin, curcumin, ellagic acid, and other valuable catechins flush out toxins from your body and neutralise the enzymatic actions of your organs.


</p>
    </a>
  
 
</div>
    </div>
</Card>   
</div>
</div>
</div>
</div>
</div>

)
    }
    
    export default Blog;