import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';
import { Container } from '@mui/material';
import Marquee from 'react-fast-marquee';

const Content = () => {
  return (
   <div>
    <div className="container mx-auto">
      <div className="flex flex-col justify-full items-center">
      <div className="w-full md:w-full lg:w-full">
    <Marquee className='brightness-80 w-820 h-360' direction="left" speed={30} delay={2} pauseOnHover gradientWidth="200">
    <img className='w-full h-360px p-4' src="https://i.pinimg.com/originals/ce/6e/20/ce6e20831df58302772834004d469d16.jpg" />
    <img className='w-full h-360px p-4' src="https://i.pinimg.com/originals/61/ca/79/61ca7992d9e81d3d068a2424734d6967.jpg" />
    <img className='w-full h-360px p-4' src="https://i.pinimg.com/originals/0c/40/1a/0c401a86372fbbc2319e1885263732b0.jpg" />
    <img className='w-full h-360px p-4' src="https://i.pinimg.com/originals/a4/6e/c5/a46ec5b181ee2315faf255e4d2aab3eb.jpg" />
    
</Marquee>
</div>
</div> 
</div>
</div>
  )
}

export default Content