import { Icon } from '@mui/material'
import React from 'react'
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);
const Aboutalpha = () => {
  return (
    
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <div>
        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider 
              text-white uppercase rounded-sm bg-gradient-to-r from-yellow-900 to-yellow-500">
          SANKRANTI EXPORTS
        </p>
      </div>
      <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        <span className="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
          >
            <defs>
              
            </defs>
            <rect
              fill="url(#27df4f81-c854-45de-942a-fe90f7a300f9)"
              width="52"
              height="24"
            />
          </svg>
          <span className="relative mb-4 text-2xl font-bold leading-none sm:text-4xl 
             bg-gradient-to-r from-yellow-900 to-yellow-500 inline-block  text-transparent bg-clip-text ">Our Vision
            </span>
        </span>{' '}
       
      </h2>
      <p className="text-base text-gray-700 md:text-lg">
      Our vision is to be a leading global exporter of high-quality, sustainable millet and food products that nourish and promote healthy living, while fostering long-term relationships with our customers and stakeholders.

      </p>
    </div>

    <div>
      <h2 class='text-yellow-900 text-4xl text-center'><b>Core Values</b></h2>
      <br classname='inline-block'></br>
    </div>
    <div class="flex flex-wrap">
    
    
  
    <a href="#" class="flex flex-center block max-w-full p-6 bg-white border bg-gradient-to-r from-yellow-900 to-yellow-500 rounded-lg mx-auto">
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"> </h5>
      <p class=" text-white text-md text-justify"><b>Quality:</b> We are committed to providing our customers with the highest quality millet and food products that meet or exceed their expectations.<br/>
<br classname="inline-block"></br>
<b>Integrity:</b> We conduct our business with honesty, transparency, and ethical principles that foster trust and respect with our customers and stakeholders.<br/>
<br classname="inline-block"></br>
<b>Innovation:</b> We continuously explore new ideas, technologies, and approaches to improve our products, services, and processes.<br/>
<br classname="inline-block"></br>
<b>Customer Focus:</b> We prioritize the needs and satisfaction of our customers by delivering timely and reliable products and services that meet their requirements<br/>
<br classname="inline-block"></br>
<b>Professionalism:</b> We conduct ourselves in a professional manner, with a focus on excellence, accountability, and continuous improvement.<br/>
<br classname="inline-block"></br>
<b>Reliability:</b> We are committed to delivering our products and services on time, with consistency, and with a high degree of reliability.<br/>
<br classname="inline-block"></br>
<b>Global Perspective:</b> We value diversity, cultural sensitivity, and a global perspective in our business practices, and strive to be a responsible global citizen</p>
    </a>
  
  
  
  
  
 
</div>
    <div>
      <h2 class='text-yellow-900 text-4xl text-center'><b>About us</b></h2>
      <br classname='inline-block'></br>
    </div>
    <div class="flex flex-wrap">
    
    
  
    <a href="#" class="block max-w-100% p-6 bg-white border bg-gradient-to-r from-yellow-900 to-yellow-500 rounded-lg shadow">
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"> </h5>
      <p class=" text-white text-md text-justify">Sankranti Exports, the premier millet exporter dedicated to delivering high-quality products worldwide. We prioritize healthy food choice and holistic well-being, sourcing the finest millet varieties from local farmers practicing sustainable and eco-friendly methods. Our expert team carefully selects grains that meet stringent quality standards before packaging and shipping to our valued customers.<br/>
<br classname="inline-block"></br>
Transparency is at the core of our business, as we provide comprehensive product information. Additionally, we offer flexible shipping options tailored to meet our customers' unique requirements.<br/>
<br classname="inline-block"></br>
Our unwavering commitment to customer satisfaction drives us to provide exceptional service. With our experienced team on hand, we are ready to address any inquiries or concerns. Whether you're a small business owner, food manufacturer, or retailer, we are fully equipped to fulfill your millet needs.</p>
    </a>
  
  
  
  
  
 
</div>

      </div>

      
      
)
}

export default Aboutalpha