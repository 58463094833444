import React,{useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import Menu from './Menu';
import About from './About';
import Navbar from './Navbar';
import  Footer from './Footer';
import  Products from './Productspage';
import  Millets from './Millets';
import  Recipies from './Recipies';
import HealthBenifits from './HealthBenifits';
import Blog from './Blog';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path='about' element={<About />} />
        <Route path='Productspage' element={<Products />} />
        <Route path='Millets' element={<Millets />} />
        <Route path='Recipies' element={<Recipies />} />
        <Route path='HealthBenifits' element={<HealthBenifits />} />
        <Route path='Blog' element={<Blog />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App