import React from 'react'
import Marquee from 'react-fast-marquee';

export const Homeabout = () => {
    
    return (
        
<div>
  <div className="px-4 py-6 mx-auto sm:max-w md:max-w lg:max-w-screen md:px-16 lg:px-8 lg:py-1">
    <h2 class="text-yellow-900 text-center text-4xl"><b>Products</b></h2>
    <div className="marquee"></div>
<Marquee className='brightness-80' direction="right" speed={100} delay={2} pauseOnHover gradientWidth="200">
<div className='container h-auto w-full'></div>
<div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-1">
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  </div>
  <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
  <a href="/" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/75/78/85/7578851c60627c92499d343bf9e15a6c.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Sorghum <br/>Great Millet </p></div>
  </div>
</a>
<a href="/" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/4a/01/9d/4a019da2af74d24d5ff64c5da4d0aea6.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Finger Millet <br/> Ragi</p></div>
  </div>
</a>
<a href="/" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/9e/ec/5a/9eec5ae5c612498dacae7769735e7307.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Little Millet<br/> Suji </p></div>
  </div>
</a>
<a href="/" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/e9/ff/ee/e9ffee2b5538426747a9d8ccd21a3f7b.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Pearl Millet <br/> Flour</p></div>
  </div>
</a>
<a href="/" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/38/51/1d/38511da6e489ccb2f2a6d933f4270ae6.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Pearl Millet <br/> Processed Grain </p></div>
  </div>
</a>
<a href="/" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/07/67/95/07679539ce99660fa910a5aa8d71e0c8.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">RAGI <br/> & RAGI POWDER </p></div>
  </div>
</a>  
    </div>
      </div>
      </Marquee>
   
    </div>
    </div>
    )
}
export default Homeabout;