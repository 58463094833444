import React from 'react';
import logo from './assets/Logo.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  return (
    <div>
      <footer class="text-gray-400  bg-yellow-900  body-font">
  <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <a class="flex title-font font-medium items-center md:justify-start justify-center text-white">
    <span class="ml-2  text-xl font-bold tracking-wide text-gray-100 uppercase">
                  <img src={logo} alt="flione" className="max-w-[110px] "/>
                </span>
    </a>
    <p class="text-sm text-gray-400 sm:ml-4 sm:pl-4 sm:border-l-2  sm:border-gray-800 sm:py-2 
    sm:mt-0 mt-4">Copyright © 2023 - 
      <a href="/" class="text-white ml-1" target="_blank" rel="noopener noreferrer"> SANKRANTI EXPORTS</a>
    </p>
    <p>
    <a href="https://www.flioneit.com" class="text-blue ml-1" target="_blank" rel="noopener noreferrer"> Managed by FLIONE </a>

    </p>
    <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <a href="https://www.facebook.com/people/Sankranti-Exports/100092628736942/" class="text-gray-400 hover:text-gray-100">
        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
          <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
        </svg>
      </a>
      <a href="https://www.instagram.com/sankrantiexports/" class="ml-3 text-gray-400 hover:text-gray-100">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
        </svg>
      </a>
       <a
   href="https://www.youtube.com/@SankrantiExports"
   class="ml-3 text-gray-400 hover:text-gray-100 -mt-1"
                 
                  >
                     <YouTubeIcon />
                  </a>
                  <a
                    href="https://wa.me/message/I7VM67TMHXSIJ1"
                    class="ml-3 text-gray-400 hover:text-gray-100 -mt-1"
                  >
                       <WhatsAppIcon />
                  </a>
    </span>
  </div>
</footer>
    </div>
  )
}

export default Footer