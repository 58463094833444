import React from 'react'
import Content from './Content';
import Content2 from './Content2';
import Content3 from './Content3';
import Homeabout from './Homeabout';
import Content4 from './Content4';
import Content5 from './content5';
import Programslider from './programslider';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);
const Menu = () => {
  return (
    <div>
      <Content />
      <Content3 />
      <Homeabout/>
      <Content5 />
    </div>
  )
}

export default Menu