import React, { useRef } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);
const Form = () => {


    const form = useRef();


  return (
    <div>
        


<section class="min-h-screen bg- dark:bg-white">
    <div class="container px-2 py-6 mx-auto">
        <div class="lg:flex lg:items-center lg:-mx-10">
            <div class="lg:w-1/2 lg:mx-10">
                <h1 class="text-2xl font-semibold    bg-gradient-to-r from-yellow-900 to-yellow-500 inline-block  text-transparent bg-clip-text  capitalize dark:text-gray lg:text-3xl">Let’s talk</h1>

                <p class="mt-2 text-gray-500 dark:text-gray-400">
                Please let us know what you would like to learn or discuss with us.

                </p>

                <form class="mt-2 " action='https://formspree.io/f/mvonyvdz' method='POST' >
                    <div class="-mx-2 md:items-center md:flex">
                        <div class="flex-1 px-2">
                            <label class="block mb-2 text-sm text-gray-600 dark:text-gray-700">Full Name</label>
                            <input type="text" placeholder="John Doe" name="username"
                             class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div class="flex-1 px-2 mt-4 md:mt-0">
                            <label class="block mb-2 text-sm text-gray-600 dark:text-gray-700">Email address</label>
                            <input type="email" placeholder="johndoe@example.com" name="Email"
                            class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>
                    </div>

                    <div class="w-full mt-4">
                        <label class="block mb-2 text-sm text-gray-600 dark:text-gray-700">Message</label>
                        <textarea  name="message"
                        class="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 
                        rounded-md md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 
                        focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none 
                        focus:ring focus:ring-opacity-40" placeholder="Message"  ></textarea>
                        {/* <input  type="submit" value="Send" /> */}
                    </div>

                    <button class="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors 
                    duration-300 transform bg-gradient-to-r from-yellow-900 to-yellow-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                        get in touch
                    </button>
                </form>
            </div>

            <div class="mt-12 lg:flex lg:mt-0 lg:flex-col lg:items-center lg:w-1/2 lg:mx-10">
                    <div class="mt-2 space-y-4 md:mt-6">
                        <p class="text-yellow-900 text-justify">Thank you for your interest in our company! 
</p>      
<h2>For Detail & Faster reply</h2>
<p>Email us at:<br class='inline-block'></br><b class='text-yellow-900'>sankrantiexp16@gmail.com</b></p>       

                    </div>
<div className='inline-block'>

</div>
<div class="mt-2 -w-10 md:mt-2">
<h3 class="text-gray-700 dark:text-gray-700 -mx-20"><b>Follow us</b></h3>

<div class="flex mt-4  -mx-20 ">       

                                <a href="https://www.facebook.com/people/Sankranti-Exports/100092628736942/"  >
                                   < FacebookIcon  className='hover:text-yellow-500  mr-3 '/>
                                  
                                </a>
                                <a href="https://www.instagram.com/sankrantiexports/"  >
                                  <InstagramIcon className='hover:text-yellow-500  mr-3' />
                                
                                </a>
                                
                                <a      href="https://wa.me/message/I7VM67TMHXSIJ1"  >
                                <WhatsAppIcon className='hover:text-yellow-500  mr-3'/>
                                 
                                </a>
                                <a      href="https://www.youtube.com/@SankrantiExports"  >
                                <YouTubeIcon className='hover:text-yellow-500  mr-3'/>
                                 
                                </a>
                              
                              

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}

export default Form