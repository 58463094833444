import React from 'react';
import Aboutalpha from './Aboutalpha';
// import Pricing from './Pricing';
import Form from './Form';
import Map from './Map';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);
const About = () => {
  return (
    <div>
<Aboutalpha />
<Form />
{/* <Pricing /> */}
<Map />

    </div>
  )
}

export default About