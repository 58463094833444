import React from 'react';
import { Card } from '@mui/material';
import { ExternalLink } from 'react-external-link';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);
const HealthBenifits = () => {
    return (

<div>
      <div className="container mx-auto">
        <div className="flex flex-row justify-center items-center mb-4 mt-4">
          
            <Card>
              <div>
                <div class="flex flex-wrap">
                  <a
                    href="#"
                    class="flex flex-center block max-w-full  md:w-1/2 lg:w-1/2 p-6 bg-white border bg-gradient-to-r from-yellow-900 to-yellow-500 rounded-lg mx-auto"
                  >
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"> </h5>
      <p class=" text-white text-md text-justify">- Health-promoting nutritious crop: Compared to other cereals they have superior micronutrient profile and bioactive flavonoids.<br/>
<br classname="inline-block"></br>
- Millets have a low Glycaemic Index (GI) and also associated with the prevention of diabetes.<br/>
<br classname="inline-block"></br>
- They are good source of minerals like iron, zinc, and calcium.<br/>
<br classname="inline-block"></br>
- Millets are gluten-free and can be consumed by celiac disease patients.<br/>
<br classname="inline-block"></br>
- Millet has a beneficial effect on the management and prevention of hyperlipidemia and risk of CVD.<br/>
<br classname="inline-block"></br>
- Millets are found to be helpful with the reduction of weight, BMI, and high blood pressure.<br/>
<br classname="inline-block"></br>
- In India, Millet is generally consumed with legumes, which creates mutual supplementation of protein, increases the amino acid content, and enhances the overall digestibility of protein.
<br classname="inline-block"></br>
- Millet based value-added products in ready to cook, ready to eat category are easily accessible and convenient to the urban population.
<br classname="inline-block"></br>
- Millets are used for dual purposes as food as well as fodder, which make it more farming efficient.
<br classname="inline-block"></br>
- Millet cultivation helps to reduce the carbon footprint.
</p>
    </a>
  
  
    <a
  href="#"
  className="flex flex-center block max-w-full md:w-1/2 lg:w-1/2 p-6  mx-auto"
>
  <img src="https://apeda.gov.in/milletportal/assets/img/Health_Benefits_Millets.jpg" alt="Health Benefits Millets" />
</a>

<span className='inline-block mx-16 mb-6 md: text-sm '> 
       <h2>Note: Content reference from <ExternalLink className='text-blue-500'>https://apeda.gov.in/milletportal/health_benefits.html</ExternalLink></h2>
       
       <p></p>
       </span> 
  
 
</div>
    </div>
</Card>   
</div>
</div>
</div>





)
    }
    
    export default HealthBenifits