import { Card } from '@mui/material';
import React from 'react';
import { ExternalLink } from 'react-external-link';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ReactGA from 'react-ga';
import Marquee from 'react-fast-marquee';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);


const Item = () => {
  const openPopup = () => {
    // Logic to open the popup
    console.log('Popup opened');
  };

  return (
   <div>
    
  <Popup trigger={<a onClick={openPopup} aria-label="View Item"><div className='container h-auto w-full'></div>
    <div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
      <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
      </div>
      <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
      <a href="#" aria-label="View Item">
      <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
        <div class="block relative h-48 w-48 overflow-hidden">
          <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/9f/7c/89/9f7c8948b16c2099deeb2e25cb5d6419.jpg"/>
        </div>
        <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          {/* Popup Content */}
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="bg-transperent-70% text-white rounded-lg p-4">
              <p><b>•	Sorghum Dosa<br/>
    </b>
     </p>
              {/* Add more content as needed */}
            </div>
          </div>
        </div>
        <div>
          <p class="mb-4 text-sm text-center font-bold text-black">Sorghum  <br/>(Sorghum bicolour)</p>
        </div>
      </div>
      </a>
      </div>
      </div>
    </a>
    } modal>
      {close => (
        <div className="popup-content ">
          <div className="flex flex-col items-center">
  <Card className="mb-2 mt-2 text-center">
    <p><b>Sorghum Dosa</b></p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Ingredients:</b></h2>
      Sorghum grain - 3 cup, black gram dal - 1 cup, salt and oil (for shallow fry)
    </p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Preparation Method:</b></h2>
      • Grind the soaked sorghum grain and black gram dal together into a fine batter. Add salt for taste and allow it for fermentation. 
      <br/>• Apply a tea spoon of oil on the preheated dosa making tawa and pour the batter on it, spread with scoop into thin round shape. Fry till crisp dosa is obtained. <br/>• Serve hot with chutney.
    </p>
  </Card>
</div>
          {/* Add more content as needed */}
          <button className='bg-yellow-500 border-b-2px rounded ' onClick={close}>Close</button>
        </div>
      )}
    </Popup>
    </div>
    
    
  );
};

const Item2 = () => {
  const openPopup = () => {
    // Logic to open the popup
    console.log('Popup opened');
  };

  return (
    <div>
    <Popup trigger={<a onClick={openPopup} aria-label="View Item"><div className='container h-auto w-full'></div>
    <div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
      <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
      </div>
      <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
      <a href="#" aria-label="View Item">
      <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
        <div class="block relative h-48 w-48 overflow-hidden">
          <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/07/67/95/07679539ce99660fa910a5aa8d71e0c8.jpg"/>
        </div>
        <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          {/* Popup Content */}
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="bg-transperent-70% text-white rounded-lg p-4">
              <p><b>•	Finger Millet Laddu<br/>
    </b>
     </p>
              {/* Add more content as needed */}
            </div>
          </div>
        </div>
        <div>
          <p class="mb-4 text-sm text-center font-bold text-black">Finger Millet<br/> (Eleusine coracana)</p>
        </div>
      </div>
      </a>
      </div>
      </div>
    </a>
    } modal>
      {close => (
        <div className="popup-content ">
          <div className="flex flex-col items-center">
  <Card className="mb-2 mt-2 text-center">
    <p><b>Finger Millet Laddu</b></p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Ingredients:</b></h2>
      Finger millet flour - 1 cup, sugar - 1/2
cup powdered, ghee - 3 tsp, milk – 1/4
cup, cardamom powder-1 tsp,
coconut gratings and dry fruits – as
required chopped finely
    </p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Preparation Method:</b></h2>
      • Finger millet flour until roasted
aroma appears.<br/>
• Roast dry fruits in ghee, heat milk
and melt the jaggery.<br/>
• Add all roasted ingredients
together and mix well.<br/>
• Shape into round lemon sized
balls manually and serve.
    </p>
  </Card>
</div>
          {/* Add more content as needed */}
          <button className='bg-yellow-500 border-b-2px rounded ' onClick={close}>Close</button>
        </div>
      )}
    </Popup>
    </div>
    
  );
};

const Item3 = () => {
  const openPopup = () => {
    // Logic to open the popup
    console.log('Popup opened');
  };

  return (
    <div>
    <Popup trigger={<a onClick={openPopup} aria-label="View Item"><div className='container h-auto w-full'></div>
    <div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
      <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
      </div>
      <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
      <a href="#" aria-label="View Item">
      <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
        <div class="block relative h-48 w-48 overflow-hidden">
          <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/07/12/e0/0712e041106e6827cc8d142de24f9fdb.jpg"/>
        </div>
        <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          {/* Popup Content */}
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="bg-transperent-70% text-white rounded-lg p-4">
              <p><b>•	Pearl millet Onion Muthias<br/>
    </b>
     </p>
              {/* Add more content as needed */}
            </div>
          </div>
        </div>
        <div>
          <p class="mb-4 text-sm text-center font-bold text-black">Pearl Millet  <br/>(Pennisetum glaucum)</p>
        </div>
      </div>
      </a>
      </div>
      </div>
    </a>
    } modal>
      {close => (
        <div className="popup-content ">
          <div className="flex flex-col items-center">
  <Card className="mb-2 mt-2 text-center">
    <p><b>Pearl millet Onion Muthias</b></p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Ingredients:</b></h2>
     Pearl millet - 1 cup, onions chopped,
turmeric, chilli powder, coriander,
cumin seeds, ginger-green chilli
paste, baking soda, salt and oil – as
required.
    </p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Preparation Method:</b></h2>
      • Combine all ingredients and mix
well and knead into a semi-soft
dough.<br/>
• Apply a little oil and shape into
cylindrical and cut into slices.<br/>
• Heat the remaining oil in a nonstick
pan and add the mustard
seeds.<br/>
• Shallow fry the pieces in oil and
season with cumin seeds.<br/>
• Serve hot garnished with
coriander.
    </p>
  </Card>
</div>
          {/* Add more content as needed */}
          <button className='bg-yellow-500 border-b-2px rounded ' onClick={close}>Close</button>
        </div>
      )}
    </Popup>
    </div>
    
  );
};

const Item4 = () => {
  const openPopup = () => {
    // Logic to open the popup
    console.log('Popup opened');
  };

  return (
    <div>
    <Popup trigger={<a onClick={openPopup} aria-label="View Item"><div className='container h-auto w-full'></div>
    <div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
      <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
      </div>
      <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
      <a href="#" aria-label="View Item">
      <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
        <div class="block relative h-48 w-48 overflow-hidden">
          <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/c5/b8/6c/c5b86cb7bf6240b67bed82822c94c24f.jpg"/>
        </div>
        <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          {/* Popup Content */}
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="bg-transperent-70% text-white rounded-lg p-4">
              <p><b>•	Foxtail Millet
Mango Rice<br/>
    </b>
     </p>
              {/* Add more content as needed */}
            </div>
          </div>
        </div>
        <div>
          <p class="mb-4 text-sm text-center font-bold text-black">Foxtail Millet <br/>(Setaria italic)</p>
        </div>
      </div>
      </a>
      </div>
      </div>
    </a>
    } modal>
      {close => (
        <div className="popup-content ">
          <div className="flex flex-col items-center">
  <Card className="mb-2 mt-2 text-center">
    <p><b>Foxtail Millet
Mango Rice</b></p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Ingredients:</b></h2>
      Foxtail millet – 1 cup, water – 2 cups,
raw mango, grated – 1 or per taste,
groundnuts – 2 tsp, seasoning – curry
leaves, chillies, mustard seeds,
blackgram dal, turmeric, asafoetida,
oil, salt to taste
    </p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Preparation Method:</b></h2>
      • Cook the millet in water and let it
cool before mixing the rest of the
ingredients.<br/>
• Fry groundnuts in oil, keep aside.
• Prepare the seasoning.<br/>
• Add grated mango and saute for a
minute.<br/>
• Add the cooked millet and mix<br/>
• Tangy mango rice is ready, serve
hot.<br/>
    </p>
  </Card>
</div>
          {/* Add more content as needed */}
          <button className='bg-yellow-500 border-b-2px rounded ' onClick={close}>Close</button>
        </div>
      )}
    </Popup>
    </div>
    
  );
};

const Item5 = () => {
  const openPopup = () => {
    // Logic to open the popup
    console.log('Popup opened');
  };

  return (
    <div>
    <Popup trigger={<a onClick={openPopup} aria-label="View Item"><div className='container h-auto w-full'></div>
    <div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
      <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
      </div>
      <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
      <a href="#" aria-label="View Item">
      <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
        <div class="block relative h-48 w-48 overflow-hidden">
          <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/f7/17/98/f7179867f438e5cdc8eaaee2df65a5ee.jpg"/>
        </div>
        <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
          {/* Popup Content */}
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="bg-transperent-70% text-white rounded-lg p-4">
              <p><b>•	Kodo millet Payasam<br/>
    </b>
     </p>
              {/* Add more content as needed */}
            </div>
          </div>
        </div>
        <div>
          <p class="mb-4 text-sm text-center font-bold text-black">Kodo Millet <br/>(Paspalum scrobiculatum)</p>
        </div>
      </div>
      </a>
      </div>
      </div>
    </a>
    } modal>
      {close => (
        <div className="popup-content ">
          <div className="flex flex-col items-center">
  <Card className="mb-2 mt-2 text-center">
    <p><b>Kodo millet Payasam</b></p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Ingredients:</b></h2>
      Kodo millet – 150 g, sugar – 250 g,
milk – 250 ml, saffron – 4-5 threads,
dry fruits (cashew, almond and
pista) – 50 g and ghee – 30 ml.
    </p>
  </Card>
  <Card className="mb-2 mt-2">
    <p className="mx-4">
      <h2><b className="text-yellow-600">Preparation Method:</b></h2>
      • Cook together kodo millet,
saffron and milk on slow heat
until the millet gets mashed.<br/>
• Add sugar and stir gently to cook
payasam.<br/>
• Heat ghee in a pan add all dry
fruits and roast until golden
colour and add to the cooked
payasam.<br/>
• It can be served hot or cold.<br/>
<br/>
<b>Source: ICAR-CIAE, Bhopal</b>
    </p>
  </Card>
</div>
          {/* Add more content as needed */}
          <button className='bg-yellow-500 border-b-2px rounded ' onClick={close}>Close</button>
        </div>
      )}
    </Popup>
    </div>

    
    
  );
};

const App = () => {
  const handleDownload = () => {
    // Logic to handle the PDF download
    console.log('PDF downloaded');
    // Replace the console.log with the actual download logic, such as using the 'downloadjs' library or a server endpoint.
  };

  return (
    <React.Fragment>
      <div className='flex flex-row gap-0.5 '>
        <Item />
        <Item2 />
        <Item3 />
        <Item4 />
        <Item5 />
      </div>
      <div className='flex flex-col justify-center items-center'>
  <h2><b>For More Recipes, Download Recipe Booklet</b></h2>
  <div class="flex flex-col lg:flex-row justify-center items-center">
  <a href='https://apeda.gov.in/milletportal/files/Millets_Indian_RecipesA_Healthy_choice1.pdf' download>
    <button class="bg-yellow-500 rounded shadow-lg border border-yellow-600 px-4 py-2 text-white mb-4 mt-4 lg:mr-4" onClick={handleDownload}>Indian Recipes</button>
  </a>
  <a href='https://apeda.gov.in/milletportal/files/Millets_International_Recipes.pdf' download>
    <button class="bg-yellow-500 rounded shadow-lg border border-yellow-600 px-4 py-2 text-white mb-4 mt-4 lg:mr-4" onClick={handleDownload}>International Recipes</button>
  </a>
  <a href='https://apeda.gov.in/milletportal/files/Millets_Indian_RecipesA_Healthy_choice2.pdf' download>
    <button class="bg-yellow-500 rounded shadow-lg border border-yellow-600 px-4 py-2 text-white mb-4 mt-4" onClick={handleDownload}>Other Recipes</button>
  </a>
</div>
</div>


    </React.Fragment>
  );
};



 

export default App;
