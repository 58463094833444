import React,{useState} from 'react';
import logo from './assets/Logo.png';
import { Link } from 'react-router-dom';

                             
    export const Navbar = () => {
      const [isMenuOpen, setIsMenuOpen] = useState(false);
    
      return (
        <div className=' top-0 z-50' >
        <div class="bg-white-900 border-b-2 border-gray-500">
          <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div class="relative flex items-center justify-between">
              <a
                href="/"
                aria-label="Company"
                title="Company"
                class="inline-flex items-center"
              >
               
                <span class="ml-2  text-xl font-bold tracking-wide text-gray-100 uppercase">
                  <img src={logo} alt="Sankranti Exports" className="max-w-[130px] mt-2"/>
                </span>

              </a>
              <ul class="flex items-right hidden space-x-4 lg:flex">
                <li>
                  <a
                    href="/"
                    aria-label="Home"
                    title="Home"
                    class="font-medium px-3 py-2  shadow-lg
                     tracking-wide hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black 
                     transition-colors duration-200 hover:text-teal-accent-400"
                  >
                   Home
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    aria-label="About us"
                    title="About us"
                    class="font-medium   px-3 py-2  shadow-lg
                    tracking-wide hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    <Link to="about">
                    About us
                    </Link>
                    
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    aria-label="Products"
                    title="Products"
                    class="font-medium shadow-lg  px-3 py-2  tracking-wide 
                    hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black 
                    transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    <Link to="Productspage" >  Products </Link>
               
                  </a>
                </li>
                <li>
                <a
                    href="/"
                    aria-label="services"
                    title="services"
                    class="font-medium shadow-lg hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black
                     px-3 py-2 rounded-md tracking-wide transition-colors duration-200 hover:text-teal-accent-400"
                  
                  >
                   <Link to="Millets" >   About Millets  </Link>
                  
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    aria-label="Programs"
                    title="Programs"
                    class="font-medium shadow-lg  px-3 py-2  tracking-wide 
                    hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black 
                    transition-colors duration-200 hover:text-teal-accent-400"
                  >
                    <Link to="Recipies" >  Recipes </Link>
               
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    aria-label="careers"
                    title="careers"
                    class="font-medium shadow-lg hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black
                     px-3 py-2 rounded-md tracking-wide transition-colors duration-200 hover:text-teal-accent-400"
                  >
                   <Link to="HealthBenifits" >  Health Benefits </Link>
                  
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    aria-label="careers"
                    title="careers"
                    class="font-medium shadow-lg hover:bg-yellow-500 hover:text-white bg-white px-3 py-2 rounded-md text-black
                     px-3 py-2 rounded-md tracking-wide transition-colors duration-200 hover:text-teal-accent-400"
                  >
                   <Link to="Blog" >  Blog </Link>
                  
                  </a>
                </li>

                
              </ul>

             
              <div class="lg:hidden z-50">
                <button
                  aria-label="Open Menu"
                  title="Open Menu"
                  class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline "
                  onClick={() => setIsMenuOpen(true)}
                >
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                    />
                  </svg>
                </button>
                {isMenuOpen && (
                  <div class="absolute top-0 left-0 w-full">
                    <div class="p-5 bg-gradient-to-t from-yellow-900 via-white to-white border border-yellow-400 rounded shadow-sm">
                      <div class="flex items-center justify-between mb-4">
                        <div>
                          <a
                            href="/"
                            aria-label="Company"
                            title="Company"
                            class="inline-block items-center"
                          >
                            
                            <span class="ml-2 text-xl font-bold tracking-fit text-cyan-800 uppercase">
                              Flione 
                            </span>
                          </a>
                        </div>
                        <div>
                          <button
                            aria-label="Close Menu"
                            title="Close Menu"
                            class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <ul class="space-y-4 ">
                          <li>
                            <a
                              href="/"
                             
                              class="font-medium 
                              hover:bg-white hover:text-cyan-500 w-full rounded-xs   py-1
                              tracking-wide text-gray-700 transition-colors duration-200 
                              hover:text-deep-purple-accent-400"
                            >
                             <Link to='/' > Home </Link>
                             
                            </a>
                          </li>
                          <li>
                            <a
                              href="/"
                           
                              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 
                              hover:text-cyan-500"
                            > <Link to='about' >About us</Link>
                              
                            </a>
                          </li>
                          <li>
                            <a
                              href="/"
                          
                              class="font-medium tracking-wide text-gray-700 transition-colors duration-200
                              hover:text-cyan-500"
                            >
                              <Link to="Productspage" >  Products </Link>
                             
                            </a>
                          </li>
                          <li>
                            <a
                              href="/"
                           
                              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 
                              hover:text-cyan-500"
                            >  <Link to="Millets" > About Millets  </Link>
                              
                            </a>
                          </li>
                          <li>
                            <a
                              href="/"
                             
                              class="font-medium tracking-wide text-gray-700 transition-colors duration-200 
                              hover:text-cyan-500"
                            ><Link to="Recipies" > Recipes </Link>
                         
                            </a>
                          </li>
                          <li>
                            <a
                              href="/"
                              
                              class="font-medium tracking-wide text-gray-700 mb-4 transition-colors duration-200 
                              hover:text-cyan-500"
                            > <Link to="HealthBenifits" > Health Benefits </Link>
                            
                            </a>
                          </li>
                          <li>
                            <a
                              href="/"
                              
                              class="font-medium tracking-wide text-gray-700 mb-4 transition-colors duration-200 
                              hover:text-cyan-500"
                            > <Link to="Blog" > Blog </Link>
                            
                            </a>
                          </li>
                           
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        </div>
      );
    };


export default Navbar