import React from 'react'
import ReactGA from 'react-ga';
ReactGA.initialize('G-FWR3DCHJBQ');
ReactGA.pageview(window.location.pathname + window.location.search);

const Productspage = () => {
  return (
    <div>
<div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-26">
      <div className="max-w-full mb-4 md:mx-auto sm:text-center lg:max-w-2xl md:mb-2">
        
        <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold  text-gray-900 sm:text-4xl md:mx-auto">
          
          <span className='text-center text-yellow-900'> Our Products </span>
         
        </h2>
              
      </div>
    </div>npm 
{/* second set */}


    {/* third set */}
    <h2 className="px-16 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-2 group">
          <span className='text-yellow-900'>
          Millets (Ancient Grain) 
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
        </h2>

<section class="text-gray-600 body-font">
  <div class="container h-auto w-full"></div>
<div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  </div>
  <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
  <a href="#" aria-label="View Item" className='sm-auto'>
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/07/12/e0/0712e041106e6827cc8d142de24f9fdb.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
      {/* Popup Content */}
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Pearl Millet Processed Grain <br/>
•	Pearl Millet Flour<br/>
•	Pearl Millet Dalia/Suji/Rava  </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
      <p class="mb-4 text-sm text-center font-bold text-black">Pearl Millet <br/>(Pennisetum glaucum)</p>
    </div>
  </div>
</a>


<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/9f/7c/89/9f7c8948b16c2099deeb2e25cb5d6419.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Sorghum(Great Millet) Processed Grain <br/>
•	Sorghum(Great Millet) Flour   

   </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Sorghum  <br/>(Sorghum bicolour)</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/07/67/95/07679539ce99660fa910a5aa8d71e0c8.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white text-wrap rounded-lg p-4">
          <p><b>•	Finger Millet(Ragi) Processed Grain <br/>
•	Finger Millet(Ragi) Flour   <br/>
•	Finger millet(Ragi) Suji/Dalia/Rava<br/>
•	Finger Millet (Ragi) vermicelli/Noodles/Pasta
  </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black"> Finger Millet<br/> (Eleusine coracana) </p></div>
  </div>
</a>

    </div>
      </div>
</section>

<section class="text-gray-600 body-font">
<div className='container h-auto w-full'></div>
<div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  <h2 className="px-4 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-2 group">
          <span className='text-yellow-900'>
          Small Millets
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
        </h2>
  </div>
  <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
  <a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/c5/b8/6c/c5b86cb7bf6240b67bed82822c94c24f.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Foxtail Millet Processed Grain <br/>
•	Foxtail Millet Flour
  </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Foxtail Millet <br/>(Setaria italic) </p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/8b/2c/9f/8b2c9fa1572e656fd4c1a534e1eb3634.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Barnyard Millet Processed Grain <br/>
•	Barnyard Millet Flour <br/>
•	Barnyard Millet Dalia/Suji/Rava
</b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Barnyard Millet <br/>(Echinochloa frumentacea)</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/f7/17/98/f7179867f438e5cdc8eaaee2df65a5ee.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Kodo Millet Processed Grain<br/>
•	Kodo Millet Flour<br/>
•	Kodo Millet Dalia/Suji/Rava<br/>
•	Kodo Pasta
 </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Kodo Millet <br/>(Paspalum scrobiculatum) </p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/22/9d/9f/229d9fa6656a0aeab5f503a91d65e321.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Proso Millet Processed Grain<br/>
•	Proso Millet Flour
 </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Proso Millet <br/>(Panicum miliaceum)</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/9e/ec/5a/9eec5ae5c612498dacae7769735e7307.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Little Millet Processed Grain<br/>
•	Little Millet Flour<br/>
•	Little Millet Dalia/Suji/Rava<br/>
•	Little millet vermicelli/Pasta
 </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Little Millet <br/>(Panicum sumatrense)</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/91/ac/5c/91ac5cd8ec14f200530e70cef59eeb71.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        <div class="bg-transperent-70% text-white rounded-lg p-4">
          <p><b>•	Brown top Millet Processed Grain<br/>
•	Brown top Millet Flour
 </b>
 </p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Brown top Millet <br/>(Urochloa ramosa) </p></div>
  </div>
</a>  
</div>
</div>

</section>
<div>
<section class="text-gray-600 body-font">
<div className='container h-auto w-full'></div>
<div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  <h2 className="px-4 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-2 group">
          <span className='text-yellow-900'>
          Pseudo Millets 
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
        </h2>
  </div>
  <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
  <a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/22/02/5b/22025beae07194f675ded03ea75460fe.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Buck wheat <br/>(Fagopyrum esculentum)</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/82/1f/44/821f44a92d5ab33b73457eeedfb128a5.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black"> Amaranthus <br/>(Amaranthus viridis)</p></div>
  </div>
</a>
</div>
</div>

</section>
</div>
{/* second set */}
        <div className='container h-auto w-full'></div>
<div className="px-4 py-4 mx-wrap sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-10 lg:py-1">
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  </div>
  
<h2 className="px-6 max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-2 group">
          <span className='text-yellow-900'>
          Seeds 
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
        </h2>
  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  </div>
  <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
  <a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/4e/a2/84/4ea284d249ec79a93653735e5dce9d94.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">White Chia </p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/e2/52/76/e25276fe74067bb284bd2967983dcc30.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Black chia</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/a1/35/87/a13587a42e3650886a5ab168235c27e3.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
       
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Red Quinoa </p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/45/7e/5b/457e5b1240dd13518845de45917bce25.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">White Quinoa</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/6d/b2/43/6db243d3f699e806cccff7b9b8333911.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Black Quinoa </p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/01/a7/29/01a7298dd0766210e9df38df97ad8fd4.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Flax seeds</p></div>
  </div>
</a>  
</div>

  <div className="flex flex-row mb-6 lg:justify-between lg:flex-row md:mb-8">
  </div>
  <div className="flex flex-row gap-6 row-gap-5 mb-8 mx-auto">
  <a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/23/6e/20/236e209a00027888e04562478d607839.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
       
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Sunflower seeds</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/4e/48/2d/4e482de9b8290909ad6288bfd28686cf.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Water melon seeds</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/86/dc/13/86dc1353e9ac3e22b700c3725b9e9352.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
    {/* Popup Content */}
    <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Pumpkin Seeds</p></div>
  </div>
</a>
<a href="#" aria-label="View Item">
  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg ">
    <div class="block relative h-48 w-48 overflow-hidden">
      <img alt="ecommerce" class="object-cover object-center w-full h-full block" src="https://i.pinimg.com/564x/a7/a4/a3/a7a4a34d07b93acec1317aaa8ccbb0e6.jpg"/>
    </div>
    <div class="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 ">
   {/* Popup Content */}
   <div class="absolute inset-0 flex items-center justify-center">
        
      </div>
    </div>
    <div>
    </div>
    <div><p class="mb-4 text-sm text-center font-bold text-black">Basil seeds</p></div>
  </div>
</a>


</div>

</div>
</div>
    
    
    
  )
}

export default Productspage