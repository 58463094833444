import React from 'react'
import { Link } from 'react-router-dom'

const Content3 = () => {
  return (
    <div>



    <div className="relative flex flex-col-reverse py-16 mb-5 lg:pt-0 lg:flex-col lg:pb-0">
      <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0
       lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="https://i.pinimg.com/564x/f7/bd/09/f7bd09f684d00a646725d951f13f3785.jpg"
          alt=""
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className="mb-4 lg:my-20 lg:max-w-lg lg:pr-5">
          <p className="inline-block px-3 py-px mb-4 text-xs 
          font-semibold tracking-wider text-white uppercase rounded-sm bg-gradient-to-r from-yellow-900 to-yellow-500">
           Sankranti Exports
          </p>
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight 
          
          sm:text-4xl sm:leading-none">
            Welcome to
            
          </h2>
          <p className="pr-5 mb-5  text-base text-gray-700 md:text-lg text-justify">
           Sankranti Exports, the top niche millet exporter offering high-quality products worldwide. Our commitment to healthy food choices and organic farming ensures sustainable practices and premium millet varieties. Trust us for transparent information, flexible shipping, and exceptional customer service. Choose us for your millet needs as a small business, food manufacturer, or retailer. Experience the Sankranti Exports difference today!
          </p>
          <div className="flex items-center">
           
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200
              text-yellow-900  hover:text-yellow-500 hover:animate-pulse"
            >
              <Link to="About" > learn more </Link>
              
              <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}

export default Content3